.learnRecord {
  border-radius: 6px;
  box-shadow: 0 0 10px #b3b3b3;
  margin-bottom: 12px;

  .content {
    flex: 1;
    margin: 0 15px;
    font-size: 12px;
    color: #898989;
    overflow: hidden;

    .fileName {
      font-size: 16px;
      color: #000;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .time {
    height: 16px;
    line-height: 16px;
    padding: 8px;
    border-radius: 14px;
    font-size: 12px;
    color: #f59a23;
    background: linear-gradient(to right, #fce1bf, #fefaf5);
  }
}