.popupBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
  font-size: 20px;
}

.checkListContainer {
  height: 410px;
  overflow-y: scroll;
}

.checkList {
  --border-top: 0;
  --border-bottom: 0;
}