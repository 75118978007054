.loginBox {
  height: 100vh;
  box-sizing: border-box;
  padding: 15vh 10vw;
  background: #fff;

  .title {
    font-weight: 400;

    .logIn {
      font-size: 40px;
    }

    .text {
      font-size: 18px;
      padding-top: 10px;
    }
  }

  .form {
    margin-top: 18vw;

    .adm-list-body {
      border: 0;
    }
  }
}

.checkListContainer {
  height: 410px;
  overflow-y: scroll;
}

.checkList {
  --border-top: 0;
  --border-bottom: 0;
}