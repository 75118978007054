.trainingContent {
  border-radius: 6px;
  box-shadow: 0 0 10px #b3b3b3;
  margin-bottom: 12px;

  .imgItem {
    width: 36px;
    height: 36px;
    border-radius: 15px 0 15px 15px;
  }

  .content {
    flex: 1;
    margin: 0 15px;
    font-size: 12px;
    color: #898989;
    overflow: hidden;

    .fileName {
      display: flex;
      align-items: center;
      font-size: 16px;

      span {
        flex: 1;
        color: #000;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .time {
    height: 16px;
    line-height: 16px;
    padding: 8px;
    border-radius: 14px;
    font-size: 12px;
    background: linear-gradient(to right, gainsboro, #c4c4c400);
  }
}