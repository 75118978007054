.safeLearnDetailBox {
  height: 100vh;
  background: #fff;

  .navBarBox {
    background: #64b99c;
    color: #fff;
  }

  .time {
    width: 32vw;
    text-align: center;
    margin: 2px auto 10px;
    border-radius: 6px;
    box-shadow: 5px 5px 10px #888888;

    .title {
      height: 24px;
      line-height: 24px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      background: #f59a23;
      color: #fff;
      border-bottom: solid #b8741a 4px;
    }

    .text {
      height: 30px;
      line-height: 30px;
      color: #94948d;
    }
  }
}