.learnRecord {
  border-radius: 6px;
  box-shadow: 0 0 10px #b3b3b3;
  margin-bottom: 12px;

  .content {
    flex: 1;
    margin: 0 15px;
    font-size: 12px;
    color: #898989;
    overflow: hidden;

    .fileName {
      font-size: 16px;
      color: #000;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.rightOps{
  display: flex;
  align-items: center;
  .score{

  }
  .rexamination{
    color: #64b99c;
  }
  .amination{
    color: firebrick;
  }
  .line{
    margin: 0 6px;
    width: 1px;
    height: 20px;
    background-color: #868383;
  }
  span{
    display: block;
    text-align: center;
    font-size: 12px;
    line-height: 16px;
  }
}