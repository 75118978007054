.userBox {
  height: 100%;
  padding: 0 6vw;

  .userInfo {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 6vh;
    font-size: 16px;
    color: #fff;

    .userInfoLeft {
      .name {
        font-size: 18px;
      }

      div {
        margin-bottom: 6px;
      }
    }

    .userImg {
      width: 80px;
    }
  }
}

.logOutBox {
  text-align: center;
  font-size: 18px;

  .text {
    padding: 10px;
  }

  .logOut {
    color: red;
    border-bottom: 10px solid #eee;
  }
}