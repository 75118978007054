.recordBox {
  display: flex;
  justify-content: center;
  height: 20vh;
  color: #fff;
  font-size: 18px;
  .recordItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
