.overlayContent {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  margin-top: -75px;
  margin-left: -75px;
  background: white;
  border-radius: 16px;
}