.myInfoBox {
  height: 100vh;
  background: #fff;

  .userImgBox {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 6vh;

    .userImg {
      width: 80px;
    }
  }

  .infoItem {
    display: flex;
    justify-content: space-between;

    .text {
      color: #9296affe;
    }
  }
}