.HomeContent {
  height: 100%;
  padding: 0 20px;

  .CardStyle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    .title {
      color: #64b99c;
      font-size: 24px;
      margin-bottom: 6px;
    }
  }
}
