.sueccss {
  .adm-result-page-bg {
    background-color: #64b99c;
  }
}

.error {
  .adm-result-page-bg {
    background-color: #ffa937;
  }
}

.errorNav {
  background-color: #ffa937;
}

.sueccssNav{
  background-color: #64b99c;
}